<template>
    <section>
        <search-bar-register
        class="mt-2 mb-2 sm:mb-3"
        title="Pesquisa de Farmacopeia"
        @input="filter = $event"
        />
        <b-table
        responsive="sm"
        :fields="fields"
        primary-key="Farmacopeia"
        :items="items"
        :filter="filter"
        :perPage="itemsPerPage"
        show-empty
        empty-text="Não existem farmacopeias cadastradas"
        :empty-filtered-text="`Famacopeia não encontrada`"
        >
            <template #cell(Farmacopeia)="data">
                {{ Indice + (data.index + 1) }}
            </template>
            <template #cell(ações)="data">
                <div class="acoes d-flex justify-center gap-5">
                    <div>
                        <button @click="RedirectUrl(`view/${data.item.id}`)">
                            <EyeIcon class="w-5 mr-2 icon__actions" />
                        </button>
                    </div>
                    <div>
                        <button @click="RedirectUrl(`edit/${data.item.id}`)">
                            <EditIcon class="w-4 mr-2 icon__actions" />
                        </button>
                    </div>
                    <div>
                        <button @click="deletarEnsaio(data.item.id)">
                            <DeleteIcon class="w-5 mr-2 icon__actions" />
                        </button>
                    </div>
                </div>
            </template>
        </b-table>
        <footer class="mt-2 flex items-center">
            <Pagination
            :totalItems="totalItems"
            :itemsPerPage="itemsPerPage"
            @input="ListaFarmacopeia"
            text="Farmacopeia"
            />
        </footer>
        <div class="w-full flex items-center justify-start gap-5 my-10">
            <Button
            class="bg-gray-900 shadow shadow-[rgba(200, 200, 200, 0.7)] hover:bg-gray-700 transition-all duration-300"
            buttonText="Voltar"
            :onClick="RedirectUrlCreate"
            />
        </div>
        <Modal
        v-if="modalIsOpen"
        :modalIsOpen="modalIsOpen"
        :closeModal="closeModal"
        :buttonOptions="true"
        :confirmarDelete="confirmarDelete"
        :DeleteId="deletar"
        :modalText="$t('commons.modalTextDelete')"
        />
        <Modal
        v-if="modalDeleted"
        :modalIsOpen="modalDeleted"
        :closeModal="closeModal"
        :modalText="$t('commons.modalTextDeleted')"
        />
    </section>
</template>

<script>
import { BTable } from "bootstrap-vue";

import SearchBarRegister from "@core/components/register/SearchBarRegister.vue";
import Pagination from "@core/components/commons/pagination/Pagination.vue";
import PageOperationsEnum from "@core/helpers/constants/pagesOperation";
import Modal from "@core/components/commons/modal/Modal.vue";
import Button from "@core/components/commons/button/Button";

import EyeIcon from "@/assets/img/eye.svg";
import EditIcon from "@/assets/img/editar.svg";
import DeleteIcon from "@/assets/img/delete.svg";

export default {
    name: 'ListFarmacopeia',
    components: {
        BTable,
        SearchBarRegister,
        Pagination,
        Modal,
        Button,
        EyeIcon,
        EditIcon,
        DeleteIcon,
    },
    data() {
        return {
            modalIsOpen: false,
            modalDeleted:false,
            Indice: 0,
            totalItems: 0,
            itemsPerPage: 2,
            PageOperationsEnum,
            deletar: null,
            filter: null,
            fields: [
                "Farmacopeia",
                { key: "nomeFarmacopeia", label: "Nome", sortable: true },
                { key: "ações" }
            ],
            items: [],
         }
    },
    created() {
        this.ListaFarmacopeia();
    },
    methods: {
        closeModal(event) {
            if (event.target === event.currentTarget) {
                this.modalIsOpen = false;
                this.modalDeleted = false;
                window.scrollTo({
                top: 0,
                behavior: "smooth",
                });
            }
        },
        RedirectUrl(value) {
            this.$router.push(`/groupProducts/farmacopeia/${value}`);
        },
        RedirectUrlCreate() {
            this.$router.push(`/groupProducts/create`);
        },
        deletarEnsaio(id) {
            this.modalIsOpen = true;
            this.deletar = id;
        },
        confirmarDelete(id) {
            this.$http({
                url: `/Farmacopeia/remover/${id}`,
                method: "Post",
            }).then(() => {
                this.modalIsOpen = false;
                this.modalDeleted = true;
                setTimeout(() => this.$router.go(), 2000);
            });
        },
        ListaFarmacopeia(page = 1) {
            this.$http({
                url: `/Farmacopeia/obter-grid/${page}`,
                method: "Get",
            }).then((response) => {
                this.items = response.data.itens;
                this.totalItems = response.data.total;
                this.itemsPerPage = response.data.itensPorPagina;
                this.Indice = page * response.data.itensPorPagina - response.data.itensPorPagina;
            });
        },
    },
}
</script>