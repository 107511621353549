import HeaderRegister from "@core/components/register/HeaderRegister.vue";
import ListFarmacopeia from "@/components/groupProducts/farmacopeia/ListFarmacopeia";

export default {
  components: {
    HeaderRegister,
    ListFarmacopeia,
  },
  data() {
    return {
      previewButtonIsVisible: true,
    };
  },
};
